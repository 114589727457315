/** Background customization */
.main-page {
  min-height: 100vh;
  background-color: #0e0e14;
  position: relative;
}

.main-page__dev-name {
  padding: 5vh 0px 0px 15vw;
  font-size: 28px;
}

.main-page__dev-name--white {
  color: white;
}

.main-page__bracket--blue {
  color: rgb(22, 133, 223);
}

/** Title customization */

.greetings {
  margin: 18vh 0px 0px 15vw;
  display: flex;
  align-items: start;
  flex-direction: column;
  color: white;
  width: auto;
}

.greetings__line1 {
  text-transform: lowercase;
  font-size: 80px;
  font-weight: 100;
  font-family: monospace;
  margin-bottom: 12px;
  letter-spacing: 21px;
  z-index: 1;
}

.greetings__line2 {
  font-size: 120px;
  font-weight: bold;
  margin-bottom: 15px;
  z-index: 1;
}

.greetings__line3 {
  letter-spacing: 21px;
  color: rgb(22, 133, 223);
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 18px;
  z-index: 1;
}

.greetings__line3 .type-writer {
  border-right: solid white;
  border-width: 5px;
  white-space: nowrap;
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  animation: typing 3s steps(13, end),
    blink-caret 1s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

  from,
  to {
    border-color: #0966C2
  }

  50% {
    border-color: transparent
  }
}

.greetings__line_avatar {
  width: 100%;
}
.greetings__line4 {
  width: 100%;
}

/**
  Button customization
*/
.ant-btn-default:hover,
.ant-btn-default:not(:disabled):hover {
  border-color: transparent;
}

.btn-download-cv.ant-btn-default {
  box-shadow: -1px -1px 8px #2863cf, -6px 2px 7px #6F22D3, -3px 6px 17px #a82ce2;
  border-color: transparent;
  margin-right: 15px;
  color: white;
}

.btn-download-cv.ant-btn-default:hover {
  color: white;
  transform: scale(1.05, 1.05);
  transition: transform 0.1s;
}

.btn-git.ant-btn-default {
  box-shadow: -2px 1px 8px #caced4;
  border-color: transparent;
  margin-right: 15px;
  color: white;
}

.btn-git.ant-btn-default:hover {
  color: white;
  transform: scale(1.2, 1.2);
  transition: transform 0.1s;
}

.btn-linkedin.ant-btn-default {
  box-shadow: -2px 1px 8px #2863cf;
  border-color: transparent;
  margin-right: 15px;
  color: #0966C2;
}

.btn-linkedin.ant-btn-default:hover {
  transform: scale(1.2, 1.2);
  transition: transform 0.1s;
}

.btn-whatsapp.ant-btn-default {
  box-shadow: -2px 1px 8px #08a01c;
  border-color: transparent;
  margin-right: 15px;
  color: #43C553;
}

.btn-whatsapp.ant-btn-default:hover {
  color: #32d445;
  transform: scale(1.2, 1.2);
  transition: transform 0.1s;
}

/** Circle customization */
.circle {
  position: absolute;
  left: -30%;
  top: 70vh;
  width: 80vw;
  height: 50vw;
  border-radius: 50%;
  box-shadow: 3px -12px 18px rgb(202 38 180), 12px -16px 24px rgb(120 35 146), 30px -35px 95px rgb(57, 55, 190);
  background-color: #0e0e14;
  z-index: 1;
}

.avatar {
  display: none;
}

/** Responsive customization */
@media only screen and (max-width: 820px) {
  .main-page__dev-name {
    padding: 5vh 0px 0px 15vw;
    font-size: 12px;
  }

  .greetings {
    margin: 12px 0px 0px 15vw;
  }

  .greetings__line1 {
    text-transform: lowercase;
    font-size: 20px;
  }

  .greetings__line2 {
    font-size: 60px;
  }

  .greetings__line3 {
    letter-spacing: 4px;
    font-size: 12px;
  }

  .btn-download-cv.ant-btn-default {
    width: 50%;
    margin-bottom: 15px;
  }

  .avatar {
    display: inline;
    width: 75%;
  }

  .circle {
    top: 90vh;
  }

  img.portrait {
    display: none;
  }
}

@media only screen and (min-width: 821px) {
  img.portrait {
    top: 10vh;
    right: 12px;
    position: absolute;
    height: 100%;
  }
}