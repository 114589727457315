/** Scroll down animation */
span.scroll {
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  position: absolute;
  top: 90%;
  left: 90%;
}

.scrollDown {
  display: flex;
  justify-content: center;
}

.scrollDown span {
  width: 5px;
  height: 10px;
  border-radius: 30px;
  margin-top: 10px;
  position: relative;
}

.scrollDown span::before {
  position: absolute;
  content: '';
  width: 15px;
  height: 15px;
  border: none;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  transform: translateY(-10px) rotate(135deg);
  left: -6px;
  margin-top: 10px;
  opacity: 0;
  animation: ani 1.8s infinite linear;
}


.scrollDown span::after {
  position: absolute;
  content: '';
  width: 15px;
  height: 15px;
  border: none;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  transform: translateY(-10px) rotate(135deg);
  left: -6px;
  margin-top: 10px;
  opacity: 0;

  animation: ani 1.8s infinite linear;
  animation-delay: 0.8s;
}

@keyframes ani {
  0% {
    margin-top: 10px;
    opacity: 0;
  }

  50% {
    margin-top: 25px;
    opacity: 1;
  }

  100% {
    margin-top: 40px;
    opacity: 0;
  }
}