.expertise-page {
  display: flex;
  min-height: 100vh;
  width: 100%;
  z-index: 2;
  background-color: #0e0e14;
  color: white;
  position: absolute;
}

.expertise-page__about-me {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.tagcloud {
  min-width: 100% !important;
  width: 100% !important;
  min-height: 100% !important;
  height: 700px !important;
}

.about-me {
  padding: 20% 20% 20% 20%;
  font-size: large;
}

.about-me__title {
  font-family: Caveat, cursive;
  color: white;
}

.about-me__description {
  font-family: monospace;
  line-height: 30px;
}

.front-end-text {
  color: cornflowerblue;
}

.text--italic {
  font-style: italic;
  color: white;
}

@media only screen and (max-width: 820px) {
  .about-me {
    padding: 20% 10% 0% 10%;
  }
}